import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Layout, Typography, Menu } from 'antd';
import { useState } from 'react';
import { Outlet, useOutletContext } from "react-router-dom";
import SidebarMenu from '../components/SidebarMenu';

const { Sider, Content, Header } = Layout;
const { Title } = Typography;
const { SubMenu } = Menu;

export default function Dashboard({setToken}) {
  const [broken, setBroken] = useState(false);

  return (
    <Layout hasSider style={{minHeight: '100vh'}}>
      <Sider breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={broken => {
          setBroken(broken);
        }}
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          right: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <Title level={5} type='warning'
          style={{padding: '20px 5px 10px', textAlign: 'center'}}
        >بانک اطلاعات سخت‌افزاری</Title>
        <SidebarMenu />
      </Sider>
      <Layout style={{ marginRight: broken ? 0 : '200px' }}>
        <Header style={{backgroundColor: '#fff'}}>
          <Menu mode="horizontal">
            <SubMenu key="submenu-user" title="مدیر سیستم" icon={<UserOutlined />} style={{marginRight: 'auto'}}>
              <Menu.Item key="user" icon={<LogoutOutlined />} onClick={() => setToken(null)}>خروج</Menu.Item>
            </SubMenu>
          </Menu>
        </Header>
        <Content style={{ padding: broken ? '24px' : '48px' }}>
          <Outlet context={broken} />
        </Content>
      </Layout>
    </Layout>
  )
}

export function useBroken() {
  return useOutletContext();
}