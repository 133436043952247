import { useState, useEffect } from "react";
import { Table, Space, Row, Button, Form, Modal, Typography, Skeleton } from "antd";
import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import { baseUrl } from '../constants';
import qs from 'qs';
import dayjs from 'dayjs';
import jalaliday from 'jalaliday';

dayjs.extend(jalaliday);
// dayjs.calendar('jalali');

Date.prototype.toISOString = function () {
  return dayjs(this).format("YYYY-MM-DD");
};

const { Title, Text } = Typography;
const { confirm } = Modal;

export default function DataTable(props) {

  const [items, setItems] = useState([]);
  const [pagination, setPagination] = useState({total: 0, pageCount: 0});
  const [isTableLoading, setTableLoading] = useState(false);
  const [isFormLoading, setFormLoading] = useState(false);
  const [modalFormVisible, setModalFormVisible] = useState(false);
  const [editableId, setEditableId] = useState();

  const [tableSearchForm] = Form.useForm();
  const [modalForm] = Form.useForm();

  async function loadItems(params) {
    if (!params) {
      params = {}
    }
    params.populate = '*';
    setTableLoading(true);
    const query = qs.stringify(params, { encodeValuesOnly: true });
    const url = `${baseUrl}/${props.endpoint}?${query}`;
    const res = await fetch(url);
    const data = await res.json();
    setItems(data.data);
    setPagination(data.meta.pagination);
    setTableLoading(false);
  }

  function removeItem(id) {
    confirm({
      direction: 'rtl',
      title: 'آیا برای حذف آیتم مطمئن هستید؟',
      icon: <ExclamationCircleOutlined />,
      // content: 'Some descriptions',
      okText: 'بلی',
      okType: 'danger',
      cancelText: 'خیر',
      async onOk() {
        await fetch(`${baseUrl}/${props.endpoint}/${id}`, {
          method: 'DELETE'
        });
        loadItems();
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  function editItem(row) {
    let model = {};
    Object.entries(row.attributes).forEach(entry => {
      const [key, value] = entry;
      if(value && value.data) {
        model[key] = value.data.id;
        props.selectFields[key].setOptions([{value: value.data.id, label: value.data.attributes[props.selectFields[key].labelField]}])
      } else if (value && value.data === null) {
        model[key] = null
      } else {
        model[key] = value;
      }
      if(props.dateFields && props.dateFields.includes(key) && value) {
        const date = dayjs(value, 'YYYY-MM-DD');
        const jDate = date.calendar('jalali');
        model[key] = jDate;
      }
    });
    modalForm.setFieldsValue(model);
    setEditableId(row.id);
    setModalFormVisible('UPDATE');
  }

  const columns = [
    ...props.columns,
    {
      title: 'عملیات',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <a onClick={() => removeItem(record.id)}>حذف</a>
          <a onClick={() => editItem(record)}>ویرایش</a>
        </Space>
      ),
    }
  ];

  useEffect(() => {
    loadItems();
  }, []);

  async function onSubmit(data) {
    setFormLoading(true);
    let res;
    if(modalFormVisible === 'CREATE') {
      res = await fetch(`${baseUrl}/${props.endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({data})
      });
    } else if(modalFormVisible === 'UPDATE') {
      res = await fetch(`${baseUrl}/${props.endpoint}/${editableId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({data})
      });
    }
    loadItems();
    setModalFormVisible(false);
    setFormLoading(false);
    modalForm.resetFields();
  }

  function handlePage(page) {
    let params = Object.assign({}, props.searchParams);
    params.pagination = {
      page: page,
      pageSize: 10
    };
    loadItems(params);
  }

  return (
    <Space direction="vertical" style={{ width: '100%' }} size={'large'}>
      <Row justify="space-between">
        <div>
          <Title level={3}>{props.title}</Title>
          <Skeleton loading={isTableLoading} active size="small" title={false} paragraph={{ rows: 1 }}>
            <Text type="secondary">{pagination.total} مورد</Text>
          </Skeleton>
        </div>
        {props.newItemLink ? <Link to={props.newItemLink}>
          <Button type="primary" icon={<PlusOutlined />}>افزودن آیتم جدید</Button>
        </Link> : <Button type="primary" icon={<PlusOutlined />} onClick={() => setModalFormVisible('CREATE')}>افزودن آیتم جدید</Button> }
      </Row>
      <Row>
        <Form form={tableSearchForm} layout="inline" onFinish={() => loadItems(props.searchParams)}>
          {props.searchForm}
          <Button type="primary" htmlType="submit">جستجو</Button>
        </Form>
      </Row>
      <Table columns={columns} dataSource={items} loading={isTableLoading} rowKey="id" style={{ width: props.width || '100%' }}
        pagination={{total: pagination.total, onChange: handlePage, current: pagination.page, showSizeChanger: false}} showSizeChanger={false} />
      <Modal visible={modalFormVisible} 
        title={modalFormVisible === 'CREATE' ? 'ایجاد آیتم جدید' : 'ویرایش آیتم'}
        width={props.modalWidth}
        maskClosable={false}
        confirmLoading={isFormLoading}
        okText="ذخیره" cancelText="لغو"
        onCancel={
          () => {
            setModalFormVisible(false);
            modalForm.resetFields();
          }
        } onOk={() => {
          modalForm.validateFields()
            .then((values) => {
              tableSearchForm.resetFields();
              onSubmit(values);
            })
            .catch((info) => {
              console.log('Validate Failed:', info);
            });
        }}
      >
        <Form form={modalForm} layout="vertical" name="form_in_modal">
          { props.modalForm }
        </Form>
      </Modal>
    </Space>
  )
}