import { useState } from 'react';
import { Layout, Form, Input, Button, Row, Col, Card, Typography } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { baseUrl } from '../constants';
import background from '../assets/keyboard.jpg'

const { Content } = Layout;
const { Title, Text } = Typography;

export default function Login({ setToken }) {

  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const onFinish = function (values) {
    setLoading(true);
    setHasError(false);
    fetch(`${baseUrl}/auth/local`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        identifier: values.username,
        password: values.password
      })
    })
    .then((res) => {
      if (res.ok) {
        return res.json();
      } else {
        setLoading(false);
        setHasError(true);
        throw new Error('Something went wrong');
      }
    })
    .then((resJson) => {
      setToken(resJson.jwt);
      setLoading(false);
    })
    .catch((err) => {
      console.log(err);
      setLoading(false);
      setHasError(true);
    });
  }

  return (
    <Layout>
      <Content style={{ backgroundImage: `url(${background})`, paddingTop: '100px', height: '100vh', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
        <Row justify='center'>
          <Col xs={20} sm={12} md={6}>
            <Card style={{opacity: .9}}>
            <Title level={3} style={{ textAlign: 'center', marginBottom: 40 }}>بانک اطلاعات سخت‌افزاری</Title>

              <Form
                name="loginForm"
                onFinish={onFinish}
              >
                <Form.Item
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: 'لطفاً نام کاربری را وارد کنید!',
                    },
                  ]}
                >
                  <Input prefix={<UserOutlined />} placeholder="نام کاربری" />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: 'لطفاً کلمه عبور را وارد کنید',
                    },
                  ]}
                >
                  <Input
                    prefix={<LockOutlined />}
                    type="password"
                    placeholder="کلمه عبور"
                  />
                </Form.Item>

                <Form.Item>
                  <Button type="primary" htmlType="submit" loading={loading} block>
                    ورود
                  </Button>
                </Form.Item>
              </Form>

              {hasError ? <Text type='danger'>نام کاربری یا کلمه عبور اشتباه است!</Text> : ''}

            </Card>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
}