import * as React from "react";
import * as ReactDOM from "react-dom";
import { ConfigProvider } from "antd";
import "./index.css";
import App from "./App";
import faIR from 'antd/lib/locale/fa_IR';

ReactDOM.render(
  <ConfigProvider direction="rtl" locale={faIR}>
    <App />
  </ConfigProvider>,
  document.getElementById("root")
);