import { useState } from "react";
import { Form, Input, Row, Col } from "antd";
import { DatePicker } from "antd-jalali";
import DataTable from "../components/DataTable";
import SelectAsync from "../components/SelectAsync";

export default function Components() {

  const [searchParams, setSearchParams] = useState({filters: {}});
  const [category, setCategory] = useState();
  const [brand, setBrand] = useState();
  const [supplier, setSupplier] = useState();
  const [person, setPerson] = useState();
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [brandOptions, setBrandOptions] = useState([]);
  const [supplierOptions, setSupplierOptions] = useState([]);
  const [personOptions, setPersonOptions] = useState([]);

  const columns = [
    {
      title: 'کد',
      dataIndex: ['attributes', 'code'],
      key: 'code',
    },
    {
      title: 'نوع قطعه',
      dataIndex: ['attributes', 'category', 'data', 'attributes', 'title'],
      key: 'categoryTitle',
    },
    {
      title: 'برند',
      dataIndex: ['attributes', 'brand', 'data', 'attributes', 'name'],
      key: 'brandName',
      responsive: ['md', 'lg']
    },
    {
      title: 'مدل',
      dataIndex: ['attributes', 'model'],
      key: 'model',
      responsive: ['lg']
    },
    {
      title: 'کاربر',
      dataIndex: ['attributes', 'person', 'data', 'attributes', 'name'],
      key: 'personName',
      responsive: ['lg']
    }
  ];
  
  function handleChangeCode(value) {
    const params = Object.assign({}, searchParams);
    params.filters.code = {
      $containsi: value
    }
    setSearchParams(params);
  }

  function handleChangeCategory(value) {
    const params = Object.assign({}, searchParams);
    params.filters.category = {
      id: {
        $eq: value
      }
    }
    setSearchParams(params);
  }

  function handleChangeBrand(value) {
    const params = Object.assign({}, searchParams);
    params.filters.brand = {
      id: {
        $eq: value
      }
    }
    setSearchParams(params);
  }

  function handleChangeSupplier(value) {
    const params = Object.assign({}, searchParams);
    params.filters.supplier = {
      id: {
        $eq: value
      }
    }
    setSearchParams(params);
  }

  function handleChangePerson(value) {
    const params = Object.assign({}, searchParams);
    params.filters.person = {
      id: {
        $eq: value
      }
    }
    setSearchParams(params);
  }

  const selectFields = {
    category: {
      setOptions: function(array) {
        console.log(array)
        setCategoryOptions(array);
      },
      labelField: 'title'
    },
    brand: {
      setOptions: function(array) {
        setBrandOptions(array);
      },
      labelField: 'name'
    },
    person: {
      setOptions: function(array) {
        setPersonOptions(array);
      },
      labelField: 'name'
    },
    supplier: {
      setOptions: function(array) {
        setSupplierOptions(array);
      },
      labelField: 'name'
    },
  };

  const searchForm = (
    <>
      <Form.Item name="code">
        <Input placeholder="کد" allowClear onChange={(e) => handleChangeCode(e.target.value)} style={{width: '150px'}} />
      </Form.Item>
      <Form.Item name="category">
        <SelectAsync labelField="title" placeholder="نوع قطعه" endpoint="categories"  onChange={handleChangeCategory} style={{width: '150px'}} ></SelectAsync>
      </Form.Item>
      <Form.Item name="brand">
         <SelectAsync labelField="name" placeholder="برند" endpoint="brands" onChange={handleChangeBrand} style={{width: '150px'}} ></SelectAsync>
      </Form.Item>
      <Form.Item name="person">
         <SelectAsync labelField="name" placeholder="کاربر" endpoint="people" onChange={handleChangePerson} style={{width: '150px'}} ></SelectAsync>
      </Form.Item>
      <Form.Item name="supplier">
        <SelectAsync labelField="name" placeholder="تأمین‌کننده" endpoint="suppliers"  onChange={handleChangeSupplier} style={{width: '150px'}} ></SelectAsync>
      </Form.Item>
    </>
  );

  const modalForm = (
    <Row gutter={16}>
      <Col xs={24} sm={12} md={8}>
        <Form.Item name="code" label="کد قطعه" 
          rules={[
            {
              required: true,
              message: 'کد قطعه الزامی است',
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={8}>
        <Form.Item name="category" label="نوع قطعه"
          rules={[
            {
              required: true,
              message: 'نوع قطه الزامی است',
            },
          ]}
        >
          <SelectAsync labelField="title" options={categoryOptions} endpoint="categories" value={category} onChange={(v) => setCategory(v)} />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={8}>
        <Form.Item name="brand" label="برند">
          <SelectAsync labelField="name" options={brandOptions} endpoint="brands" value={brand} onChange={(v) => setBrand(v)} />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={8}>
        <Form.Item name="model" label="مدل">
          <Input />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={8}>
        <Form.Item name="supplier" label="تأمین‌کننده">
          <SelectAsync labelField="name" options={supplierOptions} endpoint="suppliers" value={supplier} onChange={(v) => setSupplier(v)}  />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={8}>
        <Form.Item name="person" label="کاربر">
          <SelectAsync labelField="name" options={personOptions} endpoint="people" value={person} onChange={(v) => setPerson(v)}  />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={8}>
        <Form.Item name="buyDate" label="تاریخ خرید">
          <DatePicker format="YYYY/MM/DD" style={{width: '100%'}} />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={8}>
        <Form.Item name="warrantyStartDate" label="تاریخ شروع گارانتی">
          <DatePicker format="YYYY/MM/DD" style={{width: '100%'}} />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={8}>
        <Form.Item name="warrantyEndDate" label="تاریخ پایان گاراتی">
          <DatePicker format="YYYY/MM/DD" style={{width: '100%'}} />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item name="description" label="توضیحات">
          <Input.TextArea rows={2} />
        </Form.Item>
      </Col>
    </Row>
  );

  return (
    <DataTable columns={columns} endpoint="components" title="لیست قطعات" searchForm={searchForm} searchParams={searchParams} 
      modalForm={modalForm} modalWidth={1000} dateFields={['buyDate', 'warrantyStartDate', 'warrantyEndDate']} 
      selectFields={selectFields}
    />
  );
}