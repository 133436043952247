import { Form, Input, Row, Col } from "antd";
import { useState } from "react";
import DataTable from "../components/DataTable";

export default function Suppliers() {

  const [searchParams, setSearchParams] = useState({});

  const columns = [
    {
      title: 'نام',
      dataIndex: ['attributes', 'name'],
      key: 'name',
    },
    {
      title: 'تلفن',
      dataIndex: ['attributes', 'phone'],
      key: 'phone',
    },
    {
      title: 'موبایل',
      dataIndex: ['attributes', 'mobile'],
      key: 'mobile',
    },
  ];

  function handleChange(value) {
    setSearchParams({
      filters: {
        name: {
          $containsi: value
        }
      }
    });
  }

  const searchForm = (
    <>
      <Form.Item name="name">
        <Input placeholder="نام" allowClear onChange={(e) => handleChange(e.target.value)} />
      </Form.Item>
    </>
  );

  const modalForm = (
    <Row gutter={16}>
      <Col span={12}>
        <Form.Item name="name" label="نام" 
          rules={[
            {
              required: true,
              message: 'نام الزامی است',
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item name="phone" label="تلفن">
          <Input />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item name="mobile" label="موبایل">
          <Input />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item name="email" label="ایمیل" rules={[{type: 'email'}]}>
          <Input />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item name="website" label="وب‌سایت">
          <Input />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item name="address" label="آدرس">
          <Input.TextArea rows={2} />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item name="description" label="توضیحات">
          <Input.TextArea rows={2} />
        </Form.Item>
      </Col>
    </Row>
  );

  return (
    <DataTable columns={columns} endpoint="suppliers" title="لیست تأمین‌کنندگان" searchForm={searchForm} searchParams={searchParams}
      modalForm={modalForm} />
  )
}