import { useState } from "react";
import { Form, Input, Row, Col,Checkbox, Tooltip } from "antd";
import DataTable from "../components/DataTable";
import SelectAsync from "../components/SelectAsync";
import { InfoCircleFilled } from "@ant-design/icons";

export default function Users() {

  const [searchParams, setSearchParams] = useState({filters: {}});
  const [department, setDepartment] = useState();
  const [departmentOptions, setDepartmentOptions] = useState([]);

  const columns = [
    {
      title: 'نام',
      dataIndex: ['attributes', 'firstname'],
      key: 'firstname',
    },
    {
      title: 'نام خانوادگی',
      dataIndex: ['attributes', 'lastname'],
      key: 'lastname',
    },
    {
      title: 'کد پرسنلی',
      dataIndex: ['attributes', 'personnelCode'],
      key: 'personnelCode',
    },
    {
      title: 'واحد سازمانی',
      dataIndex: ['attributes', 'department', 'data', 'attributes', 'title'],
      key: 'categoryTitle',
      responsive: ['lg']
    },
  ];

  function handleChangeLastname(value) {
    const params = Object.assign({}, searchParams);
    params.filters.lastname = {
      $containsi: value
    }
    setSearchParams(params);
  }

  function handleChangePersonnelCode(value) {
    const params = Object.assign({}, searchParams);
    params.filters.personnelCode = {
      $containsi: value
    }
    setSearchParams(params);
  }

  function handleChangeDepartment(value) {
    const params = Object.assign({}, searchParams);
    params.filters.department = {
      id: {
        $eq: value
      }
    }
    setSearchParams(params);
  }

  const selectFields = {
    department: {
      setOptions: function(array) {
        setDepartmentOptions(array);
      },
      labelField: 'title'
    },
  };

  const searchForm = (
    <>
      <Form.Item name="lastname">
        <Input placeholder="نام خانوادگی" allowClear onChange={(e) => handleChangeLastname(e.target.value)} style={{width: '200px'}} />
      </Form.Item>
      <Form.Item name="personnelCode">
        <Input placeholder="کد پرسنلی" allowClear onChange={(e) => handleChangePersonnelCode(e.target.value)} style={{width: '200px'}} />
      </Form.Item>
      <Form.Item name="department">
        <SelectAsync labelField="title" placeholder="واحد سازمانی" endpoint="departments"  onChange={handleChangeDepartment} style={{width: '200px'}} />
      </Form.Item>
    </>
  );

  const modalForm = (
    <Row gutter={16}>
      <Col xs={24} sm={12}>
        <Form.Item name="firstname" label="نام" 
          rules={[
            {
              required: true,
              message: 'نام الزامی است',
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12}>
        <Form.Item name="lastname" label="نام خانوادگی" 
          rules={[
            {
              required: true,
              message: 'نام خانوادگی الزامی است',
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12}>
        <Form.Item name="personnelCode" label="کد پرسنلی"
          rules={[
            {
              required: true,
              message: 'کد پرسنلی الزامی است',
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12}>
        <Form.Item name="nationalCode" label="کد ملی">
          <Input />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12}>
        <Form.Item name="mobile" label="موبایل">
          <Input />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12}>
        <Form.Item name="department" label="واحد سازمانی">
          <SelectAsync labelField="title" options={departmentOptions} endpoint="departments" value={department} onChange={(v) => setDepartment(v)} />
        </Form.Item>
      </Col>
      <Col xs={24}>
        <Form.Item name="isSystemUser" valuePropName="checked">
          <Checkbox>کاربر سیستم</Checkbox> 
          {/* <Tooltip title="با فعال کردن این گزینه، کاربر می‌تواند با کد پرسنلی به عنوان نام کاربری و کلمه عبور وارد سیستم شود.">
            <InfoCircleFilled />
          </Tooltip> */}
        </Form.Item>
      </Col>
    </Row>
  );

  return (
    <DataTable columns={columns} endpoint="people" title="لیست کاربران" searchForm={searchForm} searchParams={searchParams} 
      modalForm={modalForm} selectFields={selectFields}
    />
  )
}