import { Tree, Space, Form, Modal, Input, Row, Typography, Button, Select } from "antd";
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useState, useEffect } from "react";
import { baseUrl } from '../constants';

const { Title } = Typography;
const { confirm } = Modal;
const { Option } = Select;

export default function Departments() {

  const [editForm] = Form.useForm();
  const [createForm] = Form.useForm();

  const [items, setItems] = useState([]);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [editableId, setEditableId] = useState();

  function list_to_tree(list) {
    var map = {}, node, roots = [], i;
    
    for (i = 0; i < list.length; i += 1) {
      map[list[i].id] = i; // initialize the map
      list[i].children = []; // initialize the children
    }
    
    for (i = 0; i < list.length; i += 1) {
      node = list[i];
      if (node.attributes.parent.data) {
        // if you have dangling branches check that map[node.parentId] exists
        list[map[node.attributes.parent.data.id]].children.push(node);
      } else {
        roots.push(node);
      }
    }
    return roots;
  }

  async function loadItems() {
    setItems([]);
    const url = `${baseUrl}/departments?populate=*`;
    const res = await fetch(url);
    const data = await res.json();
    setItems(data.data);
  }
 
  function editItem(node) {
    setEditableId(node.id);
    editForm.setFieldsValue({
      title: node.attributes.title
    })
    setEditModalVisible(true);
  }

  async function onSubmitCreate(data) {
    setFormLoading(true);
    const res = await fetch(`${baseUrl}/departments`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({data})
    });
    loadItems();
    setCreateModalVisible(false);
    setFormLoading(false);
    createForm.resetFields();
  }

  async function onSubmitEdit(data) {
    setFormLoading(true);
    const res = await fetch(`${baseUrl}/departments/${editableId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({data})
    });
    loadItems();
    setEditModalVisible(false);
    setFormLoading(false);
    editForm.resetFields();
  }

  function removeItem(id) {
    confirm({
      direction: 'rtl',
      title: 'آیا برای حذف آیتم مطمئن هستید؟',
      icon: <ExclamationCircleOutlined />,
      // content: 'Some descriptions',
      okText: 'بلی',
      okType: 'danger',
      cancelText: 'خیر',
      async onOk() {
        const res = await fetch(`${baseUrl}/departments/${id}`, {
          method: 'DELETE',
        });
        loadItems();
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  useEffect(() => {
    loadItems();
  }, []);

  return (
    <Space direction="vertical" style={{ width: '100%' }} size={'large'}>
      <Row justify="space-between">
        <Title level={3}>لیست واحدهای سازمانی</Title>
        <Button type="primary" onClick={() => setCreateModalVisible(true)}>افزودن آیتم جدید</Button>
      </Row>
      <Tree treeData={list_to_tree(items)}
        fieldNames={{ key: 'id'}}
        titleRender={(node) => (
          <Space size={'large'}>
            <span>{node.attributes.title}</span>
            <EditOutlined title="ویرایش" onClick={() => editItem(node)} />
            <DeleteOutlined title="حذف" onClick={() => removeItem(node.id)} />
          </Space>
        )}
      />

      <Modal visible={createModalVisible} 
        title='افزودن آیتم جدید'
        maskClosable={false}
        confirmLoading={formLoading}
        okText="ذخیره" cancelText="لغو"
        onCancel={
          () => {
            setCreateModalVisible(false);
            createForm.resetFields();
          }
        } onOk={() => {
          createForm.validateFields()
            .then((values) => {
              onSubmitCreate(values);
            })
            .catch((info) => {
              console.log('Validate Failed:', info);
            });
        }}
      >
        <Form form={createForm} layout="vertical" name="form_in_modal">
          <Form.Item name="title" label="عنوان" 
            rules={[
              {
                required: true,
                message: 'عنوان الزامی است',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="parent" label="واحد مافوق">
            <Select>
              {items.map(item => <Option key={item.id} value={item.id}>{item.attributes.title}</Option>)}
            </Select>
          </Form.Item>
        </Form>
      </Modal>

      <Modal visible={editModalVisible} 
        title='ویرایش آیتم'
        maskClosable={false}
        confirmLoading={formLoading}
        okText="ذخیره" cancelText="لغو"
        onCancel={
          () => {
            setEditModalVisible(false);
            editForm.resetFields();
          }
        } onOk={() => {
          editForm.validateFields()
            .then((values) => {
              onSubmitEdit(values);
            })
            .catch((info) => {
              console.log('Validate Failed:', info);
            });
        }}
      >
        <Form form={editForm} layout="vertical" name="form_in_modal">
          <Form.Item name="title">
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </Space>
  );
}