import { Form, Input } from "antd";
import { useState } from "react";
import DataTable from "../components/DataTable";
import { useBroken  } from "./dashboard";

export default function Brands() {

  const broken = useBroken();
  const [searchParams, setSearchParams] = useState({});

  const columns = [
    {
      title: 'عنوان',
      dataIndex: ['attributes', 'name'],
      key: 'name',
    },
  ];

  function handleChange(value) {
    setSearchParams({
      filters: {
        name: {
          $containsi: value
        }
      }
    });
  }

  const searchForm = (
    <>
      <Form.Item name="name">
        <Input placeholder="عنوان" allowClear onChange={(e) => handleChange(e.target.value)} />
      </Form.Item>
    </>
  );

  const modalForm = (
    <>
      <Form.Item name="name" label="عنوان" 
        rules={[
          {
            required: true,
            message: 'عنوان الزامی است',
          },
        ]}
      >
        <Input  />
      </Form.Item>
    </>
  );

  return (
    <DataTable columns={columns} endpoint="brands" title="لیست برندها" width={broken ? '100%' : '50%'} searchForm={searchForm} searchParams={searchParams}
      modalForm={modalForm} />
  )
}