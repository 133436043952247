import { Row, Col, Card, Statistic } from 'antd';
import { useState, useEffect } from 'react';
import { baseUrl } from '../constants';

export default function DashboardIndex() {

  const [componentsCount, setComponentsCount] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadCount() {
      setLoading(true);
      const res = await fetch(`${baseUrl}/countComponents`);
      const data = await res.json();
      setComponentsCount(data.pagination.total);
      setLoading(false);
    }
    loadCount();
  }, []);

  return (
    <Row gutter={16}>
      <Col xs={12} sm={8} md={6} lg={4}>
        <Card>
          <Statistic
            title="قطعات"
            value={componentsCount}
            loading={loading}
            valueStyle={{ color: '#3f8600', }}
          />
        </Card>
      </Col>
      {/* <Col span={4}>
        <Card>
          <Statistic
            title="Idle"
            value={44}
            valueStyle={{ color: '#cf1322' }}
          />
        </Card>
      </Col> */}
    </Row>
  );
}