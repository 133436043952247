import { useState, useRef, useMemo, useEffect } from "react";
import { Select, Spin } from "antd";
import debounce from 'lodash/debounce';
import qs from 'qs';
import { baseUrl } from "../constants";

export default function SelectAsync({ debounceTimeout = 800, ...props }) {

  const {labelField, ...rest} = props;

  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const fetchRef = useRef(0);

  useEffect(() => {
    function setDefaultOptions() {
      if(props.options) {
        setOptions(props.options);
      }
    }
    setDefaultOptions();
    return function cleanup() {
      setOptions([]);
    }
  }, [props.options]);

  async function fetchOptions(value) {
    const query = qs.stringify({
      filters: {
        [labelField]: {
          $containsi: value
        }
      }
    }, { encodeValuesOnly: true });
    return fetch(`${baseUrl}/${props.endpoint}?${query}`)
      .then((response) => response.json())
      .then((body) =>
        body.data.map((item) => ({
          label: item.attributes[labelField],
          value: item.id,
        })),
      );
  }

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }

        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  return (
    <Select
      showSearch
      allowClear
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...rest}
      options={options}
    />
  );

}