import { ApartmentOutlined, AppleOutlined, AppstoreAddOutlined, ControlOutlined, DashboardOutlined, IdcardOutlined, LaptopOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';

const { SubMenu } = Menu;

export default function SidebarMenu() {
  return (
    <Menu mode="inline" defaultSelectedKeys={['1']} theme="dark">
      <Menu.Item key="1" icon={<DashboardOutlined />}>
        <Link to="/dashboard">داشبورد</Link>
      </Menu.Item>
      <Menu.Item key="2" icon={<LaptopOutlined />}>
        <Link to="/dashboard/components">لیست قطعات</Link>
      </Menu.Item>
      <SubMenu key="3" title="تنظمیات" icon={<SettingOutlined />}>
        <Menu.Item key="4" icon={<UserOutlined />}>
          <Link to="/dashboard/users">کاربران</Link>
        </Menu.Item>
        <Menu.Item key="5" icon={<ApartmentOutlined />}>
          <Link to="/dashboard/departments">واحدهای سازمانی</Link>
        </Menu.Item>
        <Menu.Item key="6" icon={<AppstoreAddOutlined />}>
          <Link to="/dashboard/categories">انواع قطعات</Link>
        </Menu.Item>
        <Menu.Item key="7" icon={<IdcardOutlined />}>
          <Link to="/dashboard/suppliers">تأمین‌کنندگان</Link>
        </Menu.Item>
        <Menu.Item key="8" icon={<AppleOutlined />}>
          <Link to="/dashboard/brands">برندها</Link>
        </Menu.Item>
      </SubMenu>
    </Menu>
  )
}