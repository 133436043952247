import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Dashboard from './routes/dashboard';
import DashboardIndex from './routes/dashboardIndex';
import Components from './routes/components';
import Categories from './routes/categories';
import Users from './routes/users';
import Brands from "./routes/brands";
import Suppliers from "./routes/suppliers";
import Departments from "./routes/departments";
import Login from "./routes/login";
import "./App.css";
import useToken from './useToken';

export default function App() {
  const { token, setToken } = useToken();

  if (!token) {
    return <Login setToken={setToken} />
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="dashboard" element={<Dashboard setToken={setToken} />}>
          <Route index element={<DashboardIndex />} />
          <Route path="components" element={<Components />} />
          <Route path="users" element={<Users />} />
          <Route path="categories" element={<Categories />} />
          <Route path="brands" element={<Brands />} />
          <Route path="suppliers" element={<Suppliers />} />
          <Route path="departments" element={<Departments />} />
        </Route>
        <Route path="*" element={<Navigate to="/dashboard" replace={true} />} />
      </Routes>
    </BrowserRouter>
  );
}